import { CoreModule } from '@abp/ng.core';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { CommercialUiConfigModule } from '@volo/abp.commercial.ng.ui/config';
import { AccountAdminConfigModule } from '@volo/abp.ng.account/admin/config';
import { AccountPublicConfigModule } from '@volo/abp.ng.account/public/config';
import { AuditLoggingConfigModule } from '@volo/abp.ng.audit-logging/config';
import { OpeniddictproConfigModule } from '@volo/abp.ng.openiddictpro/config';
import { IdentityConfigModule } from '@volo/abp.ng.identity/config';
import { LanguageManagementConfigModule } from '@volo/abp.ng.language-management/config';
import { registerLocale } from '@volo/abp.ng.language-management/locale';
import { SaasConfigModule } from '@volo/abp.ng.saas/config';
import { TextTemplateManagementConfigModule } from '@volo/abp.ng.text-template-management/config';
import { HttpErrorComponent, ThemeLeptonModule } from '@volo/abp.ng.theme.lepton';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgSelectModule } from '@ng-select/ng-select';
import { CHAT_DASHBOARD_ROUTE_PROVIDER } from './chat-dashboard/providers/chat-dashboard-route.provider';
import { CHAT_APPLICATION_ROUTE_PROVIDER } from './chat-application/providers/chat-application-route.provider';
import { AGENTS_AGENT_ROUTE_PROVIDER } from './agents/providers/agent-route.provider';
import { QUEUES_QUEUE_ROUTE_PROVIDER } from './queues/providers/queue-route.provider';
import { SKILLS_SKILL_ROUTE_PROVIDER } from './skills/providers/skills-route.provider';
import { STATES_MANAGEMENTS_STATES_MANAGEMENT_ROUTE_PROVIDER } from './states-managements/providers/states-management-route.provider';
import { MONITORING_ROUTE_PROVIDER } from './monitoring/providers/monitoring-route.provider';
import { AgentMessagingSettingsComponent } from './agent-messaging-settings/components/agent-messaging-settings.component';
import { PremiumReportingConfigModule } from '@sestek/premium-reporting/config';
import { NavItemsComponent } from './nav-items/nav-items.component';
import { SharedModule } from './shared/shared.module';
import { VersionInfoComponent } from './version-info/version-info.component';
import { FooterComponent } from './shared/footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    NavItemsComponent,
    AgentMessagingSettingsComponent,
    VersionInfoComponent,
    FooterComponent,
  ],
  imports: [
    PremiumReportingConfigModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    ThemeSharedModule.forRoot({
      httpErrorConfig: {
        errorScreen: {
          component: HttpErrorComponent,
          forWhichErrors: [401, 403, 404, 500],
          hideCloseIcon: true,
        },
      },
    }),
    AccountAdminConfigModule.forRoot(),
    AccountPublicConfigModule.forRoot(),
    IdentityConfigModule.forRoot(),
    LanguageManagementConfigModule.forRoot(),
    SaasConfigModule.forRoot(),
    AuditLoggingConfigModule.forRoot(),
    OpeniddictproConfigModule.forRoot(),
    TextTemplateManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    ThemeLeptonModule.forRoot(),
    CommercialUiConfigModule.forRoot(),
    NgxsModule.forRoot([]),
    NgxSkeletonLoaderModule,
    NgSelectModule,
    SharedModule,
  ],
  providers: [
    APP_ROUTE_PROVIDER,
    CHAT_DASHBOARD_ROUTE_PROVIDER,
    CHAT_APPLICATION_ROUTE_PROVIDER,
    MONITORING_ROUTE_PROVIDER,
    AGENTS_AGENT_ROUTE_PROVIDER,
    QUEUES_QUEUE_ROUTE_PROVIDER,
    SKILLS_SKILL_ROUTE_PROVIDER,
    STATES_MANAGEMENTS_STATES_MANAGEMENT_ROUTE_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
